import {Theme} from '../models/theme.model';
import {TenantInfo} from '../models/tenant.model';
import {createReducer, on} from '@ngrx/store';
import {PlatformActions} from './action-types';
import {EmailProviderSettings} from '../models/email-provider-settings.model';
import {SupportedLanguage} from '../models/supported-language.model';
import { updateSocialMediaBarConfig } from './platform.actions';
// import { updateSocialMediaBarConfig } from './platform.actions';


export interface PlatformState {
  brandTheme: Theme,
  tenantInfo: TenantInfo,
  emailProvider: EmailProviderSettings,
  paypalScriptLoadRequested: boolean,
  sessionLanguage: SupportedLanguage,
  translations:any
  showTopBanner: boolean,
  showAppSumoUpgradeOffer: boolean,
  showBlackFridayUpgradeOffer: boolean,
  showNewYearUpgradeOffer: boolean
}

export const initialState: PlatformState = {
  brandTheme: null,
  tenantInfo: null,
  emailProvider: null,
  paypalScriptLoadRequested: false,
  sessionLanguage: null,
  translations: null,
  showTopBanner: true,
  showAppSumoUpgradeOffer: false,
  showBlackFridayUpgradeOffer: false,
  showNewYearUpgradeOffer: false
};


export const platformReducer = createReducer(
  initialState,

  on(updateSocialMediaBarConfig, (state, { socialMediaBarConfig }) => ({
    ...state,
    tenantInfo: {
      ...state.tenantInfo,
      socialMediaBarConfig
    }
  })),

  on(PlatformActions.themeChanged, (state, theme) => {
    return {
      ...state,
      brandTheme: theme
    };
  }),

  on(PlatformActions.updateStripeStatus, (state, action) => {
    return {
      ...state,
      tenantInfo: {
        ...state.tenantInfo,
        isConnectedToStripe: action.isConnectedToStripe
      }
    };
  }),

  on(PlatformActions.setTenantInfo, (state, action) => {
    return {
      ...state,
      tenantInfo: action.tenantInfo
    };
  }),

  on(PlatformActions.grantFounderPlan, (state, action) => {
    return {
      ...state,
      tenantInfo: {
        ...state.tenantInfo,
        instructorPricingPlan: <any>'founder'
      }
    };
  }),

  on(
    PlatformActions.updateSchoolDetails,
    (state, action) => {
      return {
        ...state,
        tenantInfo: {
          ...state.tenantInfo,
          ...action.changes
        }
      };
    }),

  on(PlatformActions.saveNewsletterFormContent, (state, action) => {

    const tenantInfo = {...state.tenantInfo};

    tenantInfo.newsletter = action.newsletter;

    return {
      ...state,
      tenantInfo
    };
  }),

  on(
    PlatformActions.emailProviderSettingsLoaded,
    PlatformActions.activateEmailMarketingIntegration,
    (state, action) => {
      return {
        ...state,
        emailProvider: action.emailProviderSettings
      };
    }),

  on(PlatformActions.cancelEmailMarketingIntegration, (state, action) => {
    return {
      ...state,
      emailProvider: null
    };
  }),

  on(PlatformActions.payPalScriptLoadRequested, (state, action) => {
    return {
      ...state,
      paypalScriptLoadRequested: true
    };
  }),

  on(PlatformActions.bulkEmailVerification, (state, action) => {
    return {
      ...state,
      tenantInfo: {
        ...state.tenantInfo,
        bulkEmailVerificationState: action.bulkEmailVerificationState,
        bulkEmailSendIdentity: action.bulkEmailSendIdentity
      }
    };
  }),

  on(PlatformActions.setSessionLanguage, (state, action) => {
    return {
      ...state,
      sessionLanguage: action.language
    }
  }),

  on(PlatformActions.translationsLoaded, (state, action) => {
    return {
      ...state,
      translations: action.translations
    }
  }),

  on(PlatformActions.setTopBanner, (state, action) => {
    return {
      ...state,
      showTopBanner: action.showTopBanner
    }
  }),

  on(PlatformActions.setAppSumoUpgradeOfferBanner, (state, action) => {
    return {
      ...state,
      showAppSumoUpgradeOffer: action.showAppSumoUpgradeBanner
    }
  }),

  on(PlatformActions.setBlackFridayUpgradeOfferBanner, (state, action) => {
    return {
      ...state,
      showBlackFridayUpgradeOffer: action.showBlackFridayUpgradeBanner
    }
  }),

  on(PlatformActions.setNewYearUpgradeOfferBanner, (state, action) => {
    return {
      ...state,
      showNewYearUpgradeOffer: action.showNewYearUpgradeBanner
    }
  }),

);

